@global-font: "Courier New", Courier, monospace;

.header {
  font-family: @global-font;
  font-size: 32px;
  font-weight: bolder;
  color: white;
  background-color: black;
  text-align: center;
  top: 0;
  width: 100%;
  margin-top: 10pt;
  margin-bottom: 10pt;
}

.placeholder {
  font-family: @global-font;
  font-size: 16px;
  font-style: italic;
  color: yellow;
  background-color: black;
  text-align: center;
  width: 100%;
}

footer {
  font-family: @global-font;
  font-size: 12px;
  color: white;
  background-color: black;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.body {
  background-color: black;
  width: 100%;
  margin-top: 20pt;
}
