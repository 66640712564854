@global-font: "Courier New", Courier, monospace;

.menu {
  color: red;
  background-color: black;
  font-family: @global-font;
  font-size: 18px;
  display: flex;
  margin-bottom: 10pt;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.menu > * {
  padding-left: 10pt;
  padding-right: 10pt;
  text-align: center;
  border-top-style: solid;
  border-top-width: 1pt;
  border-bottom-style: solid;
  border-bottom-width: 1pt;

  &:hover {
    background-color: white;
    color: black;
    border-color: #ff0000;
    cursor: pointer;
  }
}

.selected-menu-item {
  background-color: red;
  color: black;
  border-color: #ff0000;
  font-weight: bold;
}
