@global-font: "Courier New", Courier, monospace;
@text-background: #101010;

.contact {
  font-family: @global-font;
  font-size: 16px;
  color: red;
  background-color: black;
  width: 100%;
  margin-left: 10%;
  display: table;
}

.contact > :last-child {
  width: 50%;
  display: flex;
  flex-direction: row-reverse;
}

.row {
  margin-bottom: 20pt;
}

.row-label {
  text-align: left;
  margin-left: 5pt;
  margin-bottom: 5pt;
}

.label-box {
  display: flex;
  text-align: left;
  margin-left: 5pt;
  width: 50%;
}

.row-error {
  font-family: @global-font;
  font-size: 12px;
  font-style: italic;
  color: yellow;
  background-color: black;
  width: 100%;
  margin-left: 10%;
}

.sent {
  font-family: @global-font;
  font-size: 20px;
  font-weight: bold;
  color: green;
  background-color: black;
  width: 100%;
  text-align: center;
  margin-top: 10%;
}

.failed {
  font-family: @global-font;
  font-size: 20px;
  font-weight: bold;
  color: red;
  background-color: black;
  width: 100%;
  text-align: center;
  margin-top: 10%;
}

.link {
  margin-top: 10%;
  width: 100%;
  text-align: center;
  color: red;

  &:hover {
    color: white;
    cursor: pointer;
  }
}

.spinner {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

input {
  width: 25%;
  background-color: @text-background;
  border-style: groove;
  border-color: #ff7f7f;
  border-width: 1px;
  color: white;
}

textarea {
  width: 50%;
  height: 200pt;
  background-color: @text-background;
  border-style: groove;
  border-color: #ff7f7f;
  color: white;
  white-space: pre-wrap;
}

button {
  width: 50pt;
}
